<template>
  <v-container>
    <RepoInfo :repoName="repoName"></RepoInfo>
    <v-container class="d-flex px-0 py-2">
      <div>
        <v-img :src="`/imgs/repo/${$store.state.repo.items[repoName].imgName}`" width="700" contain></v-img>
      </div>
      <div class="ml-5 full-width">
        <div class="mt-5">
          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2">
            具体说明待补充，在微信小程序中搜索“比赛记分牌”即可体验。
          </v-alert>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import RepoInfo from '@/components/Repo/RepoInfo.vue'

export default {
  name: 'scoreboard',
  components: {
    RepoInfo
  },
  data () {
    return {
      repoName: 'scoreboard'
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
