<template>
  <div class="d-flex align-center">
    <v-icon v-if="iconSize === 'x=small'" x-small class="px-1">{{ icon }}</v-icon>
    <v-icon v-else-if="iconSize === 'small'" small style="margin-right: -6px">{{ icon }}</v-icon>
    <v-icon v-else-if="iconSize === 'medium'" medium class="pl-2 pr-0">{{ icon }}</v-icon>
    <v-icon v-else-if="iconSize === 'large'" large class="px-1 pr-1">{{ icon }}</v-icon>
    <v-icon v-else-if="iconSize === 'x-large'" x-large class="px-1">{{ icon }}</v-icon>
    <v-list-item :key="icon" v-if="isNeedClick" @click="handleClick" class="px-3">
      <v-list-item-title v-text="text"></v-list-item-title>
    </v-list-item>
    <v-list-item :key="icon" v-else class="px-3">
      <v-list-item-title v-text="text"></v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'IconText',
  props: {
    icon: {
      type: String
    },
    iconSize: {
      type: String,
      default: 'small'
    },
    href: {
      type: String
    },
    url: {
      type: String
    },
    text: {
      type: String
    }
  },
  computed: {
    isNeedClick () {
      return !!this.url || !!this.href
    }
  },
  methods: {
    handleClick () {
      if (this.url) {
        this.$util.openUrl(this.url)
      } else if (this.href) {
        this.$router.push({
          name: this.href
        })
      }
    }
  }
}

</script>

<style scoped>
</style>
