<template>
  <v-container>
    <v-row class="grey lighten-3 px-2 py-1 full-height ">
      <IconText v-if="repoInfo.repoName" icon="fa-github" class="text-h6" :text="repoInfo.repoName" :url="`https://github.com/evgo2017/${repoInfo.repoName}`" iconSize="large"></IconText>
      <div v-if="repoInfo.repoName" class="d-flex align-center ml-2">
        <img @click="openGithub" :src="`https://img.shields.io/github/stars/evgo2017/${repoInfo.repoName}`" alt="">
        <img @click="openGithub" :src="`https://img.shields.io/github/forks/evgo2017/${repoInfo.repoName}`" alt="" class="mx-2">
        <img v-if="repoInfo.npmName" @click="openNpm" :src="`https://img.shields.io/npm/dt/${repoInfo.npmName}`" alt="">
      </div>
      <div class="d-flex align-center ml-5 py-3">{{ repoInfo.description }}</div>
    </v-row>
  </v-container>
</template>

<script>
import IconText from '@/components/base/IconText.vue'

export default {
  name: 'RepoInfo',
  components: {
    IconText
  },
  props: {
    repoName: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
    repoInfo () {
      return this.$store.state.repo.items[this.repoName]
    },
    githubUrl () {
      return `https://github.com/evgo2017/${this.repoInfo.repoName}`
    },
    npmUrl () {
      return `https://www.npmjs.com/package/${this.repoInfo.npmName}`
    }
  },
  methods: {
    openGithub () {
      this.$util.openUrl(this.githubUrl)
    },
    openNpm () {
      this.$util.openUrl(this.npmUrl)
    }
  }
}
</script>

<style scoped>

</style>
