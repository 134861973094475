<template>
  <!-- 使用示例 -->
  <v-container>
    <RepoInfo :repoName="repoName"></RepoInfo>
    <v-alert type="info" class="mb-1">
      如果有时候按钮不能点击，是因为模型 zIndex 挡住了按钮。电脑屏观看效果更佳。
    </v-alert>
    <v-alert type="success" class="mb-1">
      原先 api 提供的服务出现了问题，导致加载不到数据，所以我写了 api 项目来提供支持。
    </v-alert>
    <v-btn color="primary" @click="$util.github.goToMyRepo(repoName)">
      <v-icon class="mr-2">fa-github</v-icon>去 Github
    </v-btn>
    <v-btn color="success" class="ml-2" @click="$util.github.goToMyRepo('live2d-static-api')">
      <v-icon class="mr-2">fa-github</v-icon>去 API Github
    </v-btn>
    <v-container>
      <v-row>
        <span class="text-h6">位置（position style）：</span>
      </v-row>
      <v-row>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = 'position: fixed; top: calc(50% - 250px); left: calc(50% - 200px)'">呆在中间</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = 'position: fixed; top: 0; left: 0; zIndex: 1'">呆在左上角</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = 'position: fixed; top: 0; right: 0; zIndex: 1'">呆在右上角</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = 'position: fixed; bottom: 0; left: 0; zIndex: 1'">呆在左下角</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = 'position: fixed; bottom: 0; right: 0;'">呆在右下角</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="style = ''">跟随流</v-btn>
      </v-row>
      <v-row>
        <span class="text-h6 mt-4">方向（direction）：</span>
      </v-row>
      <v-row>
        <v-btn color="primary" class="mr-2 mt-2" @click="direction = 'right'">呆在右边</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="direction = 'left'">呆在左边</v-btn>
      </v-row>
      <v-row>
        <span class="text-h6 mt-4">尺寸（size）：</span>
      </v-row>
      <v-row>
        <v-btn color="primary" class="mr-2 mt-2" @click="size = size - 100; width = width - 100; height = height - 100;">减小</v-btn>
        <v-btn color="primary" class="mr-2 mt-2" @click="size = size + 100; width = width + 100; height = height + 100;">增大</v-btn>
      </v-row>
    </v-container>
    <!-- 可尝试一个页面上多个模型： -->
    <!-- <live2d
      :model="[1, 53]"
      :direction="direction"
      :width="width"
      :height="height"
      customId="vue-live2d-mian-2"
    ></live2d> -->
    <live2d
      :style="style"
      :direction="direction"
      :size="size"
    ></live2d>
  </v-container>
</template>

<script>
/*
 * 项目中引用包时，将 import 内容替换，同时引入 css 文件：
 * import live2d from 'vue-live2d'
 * import 'vue-live2d/dist/vue-live2d.css'
 */
// import live2d from '../src/index.vue'
import live2d from 'vue-live2d'
import RepoInfo from '@/components/Repo/RepoInfo.vue'

export default {
  name: 'App',
  components: {
    RepoInfo,
    live2d
  },
  data () {
    return {
      repoName: 'vue-live2d',
      direction: 'right',
      style: '',
      width: 500,
      height: 500,
      size: 350,
      tips: {
        mouseover: [{
          selector: '.vue-live2d',
          texts: ['这样可以修改默认语句']
        }]
      }
    }
  },
  created () {
    // tips 未使用，可自行在组件绑定此参数，注意此参数只会初始化一次
    this.tips = this.customTips
  },
  methods: {
  }
}
</script>

<style>
</style>
