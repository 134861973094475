<template>
  <v-container>
    <RepoInfo :repoName="repoName"></RepoInfo>
    <v-btn color="primary" @click="$util.github.goToMyRepo(repoName)">
      <v-icon class="mr-2">fa-github</v-icon>去 Github
    </v-btn>
    <v-container class="text-h6">
      <v-row>
        <IconText icon="fa-hand-o-right" text="尝试将绿色方块移出浏览器" iconSize="medium"></IconText>
      </v-row>
      <v-row>
        <IconText icon="fa-hand-o-right" text="尝试将绿色方块移动到四角，再调整浏览器大小" iconSize="medium"></IconText>
      </v-row>
      <v-row>
        <IconText icon="fa-hand-o-right" text="尝试在浏览器/移动端访问" iconSize="medium"></IconText>
      </v-row>
      <v-row class="mt-5">
        示例绑定
      </v-row>
      <v-row>
        <code>{{ codeExample }}</code>
      </v-row>
      <v-row class="mt-5">
        如果想给组件绑定相关移动事件，和平时绑定 Vue 事件相同。
      </v-row>
      <v-row>
        <code>{{ codeEvent }}</code>
      </v-row>
    </v-container>
    <div v-move-drag style="top: 50vh; left: 50vw; height: 150px; width: 150px; background: yellowgreen;z-index: 999;"></div>
  </v-container>
</template>

<script>
import RepoInfo from '@/components/Repo/RepoInfo.vue'
import IconText from '@/components/base/IconText.vue'

export default {
  name: 'vue-move-drag',
  components: {
    RepoInfo,
    IconText
  },
  data () {
    return {
      repoName: 'vue-move-drag',
      codeExample: '<div v-move-drag style="top: 50vh; left: 50vw; height: 100px; width: 100px; background: yellowgreen;z-index: 999;"></div>',
      codeEvent: '<div v-move-drag @drag="handleDrag" style="top: 50vh; left: 50vw; height: 100px; width: 100px; background: yellowgreen;z-index: 999;"></div>'
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
