<template>
  <v-container>
    <RepoInfo :repoName="repoName"></RepoInfo>
    <v-container class="d-flex px-0 py-2">
      <div>
        <v-img :src="`/imgs/repo/${$store.state.repo.items[repoName].imgName}`" width="550" Contain></v-img>
      </div>
      <div class="ml-5 full-width">
        <div>
          <v-btn color="primary" @click="$util.github.goToMyRepo(repoName)">
            <v-icon class="mr-2">fa-github</v-icon>
              去 Github
            </v-btn>
          </div>

          <div class="mt-2">
            <v-btn color="primary" @click="$util.openUrl('https://evgo2017.lanzous.com/s/office2pdf')">
              <v-icon class="mr-2">fa-cloud</v-icon>
              蓝奏云下载
            </v-btn>
            （密码: evgo2017）
            <v-btn color="primary" @click="$util.github.goToMyRepoReleases(repoName)">
              <v-icon class="mr-2">fa-github</v-icon>
              Github Release 下载
            </v-btn>
          </div>

          <div class="mt-5">
            <v-alert
              border="top"
              colored-border
              type="info"
              elevation="2">
              具体说明待补充，请先在 Github 项目内查看。
              <span class="href-a" @click="$util.github.goToMyRepo(repoName)">前往</span>
            </v-alert>
          </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import RepoInfo from '@/components/Repo/RepoInfo.vue'

export default {
  name: 'Office2PDF',
  components: {
    RepoInfo
  },
  data () {
    return {
      repoName: 'office2pdf'
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
